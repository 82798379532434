import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

function getDark() {
  if (window.localStorage.getItem('dark')) {
    return window.localStorage.getItem('dark') === 'true'
  }
  // return window.matchMedia('(prefers-color-scheme: dark)').matches
  return false
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: getDark(),
    options: {
      // themeCache: {
      //   get: key => window.localStorage.getItem(key),
      //   set: (key, value) => window.localStorage.setItem(key, value)
      // },
      customProperties: true
      // variations: false
    },
    themes: {
      light: {
        primary: {
          base: '#272727',
          lighten2: '#333333',
          lighten5: '#f1f1f1'
        },
        secondary: '#747474',
        success: '#34B234',
        warning: '#df7f2e',
        error: '#C91D1D',
        info: '#5bc0de',
        accent: '#005640',
        anchor: '#005640',
        telekom: '#E20074',
        dortmund: '#FDE100',
        grey: colors.grey
      },
      dark: {
        primary: colors.grey,
        secondary: colors.grey.darken5,
        success: '#34B234',
        warning: '#df7f2e',
        error: '#C91D1D',
        info: '#5bc0de',
        accent: '#005640',
        anchor: '#005640',
        telekom: '#E20074',
        dortmund: '#FDE100',
        grey: colors.grey
      }
    }
  }
})
