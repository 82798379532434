// import moment from 'moment'
import posthog from '@/plugins/posthog';
export default {
  name: 'App',
  watch: {
    '$vuetify.theme.dark'(dark) {
      window.localStorage.setItem('dark', `${dark}`);
    },
    '$route.meta.title'(title) {
      !this.$store.state.title && this.$store.dispatch('setTitle', title);
    },
    '$store.state.title': {
      immediate: true,
      handler(title) {
        document.title = title ? title + ' | ' + this.$siteTitle : this.$siteTitle;
      }
    },
    '$store.state.cookieConsent.current.oneSignal': {
      immediate: true,
      handler(consent) {
        // https://documentation.onesignal.com/docs/web-push-sdk
        if (!consent) {
          return;
        }
        const user = this.$store.state.user;
        if (!user) {
          return;
        }
        this.$OneSignal.init({
          appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
          safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID,
          allowLocalhostAsSecureOrigin: true,
          notificationClickHandlerMatch: 'origin',
          notificationClickHandlerAction: 'navigate',
          welcomeNotification: {
            message: 'Sie haben sich für Push-Benachrichtigungen angemeldet. So werden die Benachrichtigungen auf Ihrem Gerät angezeigt.'
          },
          autoResubscribe: true
        });
        this.$OneSignal.setExternalUserId(user.objectId);
      }
    },
    '$store.state.user': {
      immediate: true,
      handler(user) {
        if (user && this.$mode === 'production') {
          posthog.identify(user.objectId, {
            email: user.username,
            name: user.firstName + ' ' + user.lastName
          });
          posthog.opt_in_capturing();
          return;
        }
        posthog.reset();
      }
    }
  },
  methods: {
    unload() {
      document.getElementsByTagName('html')[0]?.classList?.remove('loading');
      window.localStorage.getItem('app') && document.getElementsByTagName('html')[0]?.setAttribute('data-which-app', window.localStorage.getItem('app'));
    }
  }
};