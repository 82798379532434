import Vue, { set } from 'vue'
import { kebabCase } from 'lodash'
import Parse, { normalize } from '@/plugins/parse'
import vuetify from '@/plugins/vuetify'
import { pluralize } from '@/filters/numbers'
import { getClassName } from '@/filters/template-tags'

export const state = () => ({
  loading: false,
  selected: null,
  notFound: null,
  drawer: false,
  cubeIds: [],
  locations: {},
  // issues
  checkOnly: false,
  issues: {}
})

const HAS_CUBES = ['Offer', 'Contract', 'Booking', 'SpecialFormat', 'FrameMount', 'TaskList']

function getTaskListParentName(parent) {
  if (parent.className === 'Assembly') {
    return 'Montage ' + parent.order.no
  }
  if (parent.className === 'Disassembly') {
    return 'Demontage ' + parent.order.no
  }
  return parent.name
}

export const getters = {
  name: (state) => {
    if (!state.selected) { return }
    const className = getClassName(state.selected.className)
    const ort = state.selected.pk ? state.selected.pk.split(':')[1] : ''
    const itemName = state.selected.className === 'TaskList'
      ? ort + ` (${getTaskListParentName(state.selected.parent)})`
      : state.selected.no || state.selected.lexNo || ort || ''
    return `${className} ${itemName}`
  },
  mlKey: state => state.selected ? `${state.selected.className}-${state.selected.objectId}` : null,
  hasCubes: state => HAS_CUBES.includes(state.selected?.className),
  cubeQuery: state => new Parse.Query('Cube').containedIn('objectId', state.cubeIds),
  cubeCount: state => state.cubeIds.length,
  allowAdding(state, getters, rootState, rootGetters) {
    if (!state.selected) {
      return false
    }
    if (!rootGetters.isIntern) {
      return false
    }
    switch (state.selected.className) {
      case 'Offer':
        return state.selected.status < 1
      case 'Contract':
        return !state.selected.reservedUntil && state.selected.status < 3
      case 'Booking':
        return state.selected.status < 3 && getters.cubeCount === 0
      case 'SpecialFormat':
        return state.selected.status < 3
      case 'FrameMount':
        return state.selected.status < 3
      case 'TaskList':
        return !state.selected.status && ['scout', 'special-format', 'custom-task'].includes(state.selected.type)
      default:
        return !state.selected.status
    }
  },
  allowRemoving(state, getters, rootState, rootGetters) {
    if (!state.selected) {
      return false
    }
    if (!rootGetters.isIntern) {
      return false
    }
    switch (state.selected.className) {
      case 'Offer':
        return state.selected.status < 1
      case 'Contract':
        return state.selected.status < 3
      case 'Booking':
        return state.selected.status < 3 && !state.selected.request
      case 'SpecialFormat':
        return state.selected.status < 3
      case 'FrameMount':
        return state.selected.status < 3 && !state.selected.request
      case 'TaskList':
        return !state.selected.status && ['scout', 'special-format'].includes(state.selected.type)
      default:
        return !state.selected.status
    }
  },
  allowSelectionRating(state) {
    if (state.selected?.className === 'Contract' && state.selected.status <= 2) {
      return true
    }
    if (state.selected?.className === 'FrameMount' && state.selected.status <= 2) {
      return true
    }
    if (state.selected?.className === 'TaskList' && state.selected.type === 'scout') {
      return true
    }
    return false
  },
  allowStar(state, getters, rootState, rootGetters) {
    if (!rootGetters.isIntern) {
      return false
    }
    if (state.selected?.className === 'FrameMount' && rootGetters.isFrameManager && state.selected.status <= 2.1) {
      return true
    }
    if (state.selected?.className === 'Offer' && state.selected.showDetails?.stars) {
      return true
    }
    return false
  },
  locations(state) {
    return Object.keys(state.locations).map((pk) => {
      const count = state.locations[pk]
      const [stateId, ort] = pk.split(':')
      return { objectId: pk, stateId, ort, count }
    })
  },
  issues(state, getters, rootState, rootGetters) {
    const selected = state.selected
    const cubeIds = state.cubeIds
    if (!selected || !cubeIds.length) { return {} }
    const orderStart = selected.startsAt
    const orderEnd = (selected.autoExtendsBy && !selected.canceledAt) ? null : selected.endsAt
    const orderEarlyCancellations = selected.earlyCancellations || {}
    return [...new Set([...Object.keys(state.issues), ...cubeIds.filter(cubeId => cubeId.startsWith('VOD'))])]
      .filter(cubeId => cubeIds.includes(cubeId))
      .map((cubeId) => {
        const cube = state.issues[cubeId] || {}
        const orderCubeEarlyCanceledAt = orderEarlyCancellations[cubeId]
        const orderCubeEnd = orderCubeEarlyCanceledAt && (!orderEnd || orderCubeEarlyCanceledAt < orderEnd) ? orderCubeEarlyCanceledAt : orderEnd
        const issues = {}

        // VODHIDE
        cubeId.startsWith('VOD') && selected.className !== 'TaskList' && (issues.hasVOD = true)
        if (cube.order) {
          // check other order end date vs current order start date
          const { startsAt: cubeStart, endsAt, earlyCanceledAt, autoExtendsBy, canceledAt } = cube.order
          let cubeEnd = ((autoExtendsBy && !canceledAt) ? null : endsAt)
          if (earlyCanceledAt && (!cubeEnd || earlyCanceledAt < cubeEnd)) {
            cubeEnd = earlyCanceledAt
          }
          const cubeStartsBeforeOrderEnds = Boolean(!orderCubeEnd || cubeStart <= orderCubeEnd)
          const cubeStartsAfterOrderEnds = Boolean(orderCubeEnd && cubeStart > orderCubeEnd)
          const cubeEndsBeforeOrderStarts = Boolean(cubeEnd && cubeEnd < orderStart)
          const orderEndsBeforeCubeStarts = Boolean(cubeStartsBeforeOrderEnds && !cubeEndsBeforeOrderStarts && !cubeStartsAfterOrderEnds)
          if (['Contract', 'Booking'].includes(cube.order.className)) {
            orderEndsBeforeCubeStarts || selected.className === 'FrameMount' // frame mounts dont have a start date
              ? (issues.hasOrder = true)
              : (issues.hasEndingOrder = true)
          }
          if (cube.order.className === 'SpecialFormat') {
            orderEndsBeforeCubeStarts || selected.className === 'FrameMount' // frame mounts dont have a start date
              ? (issues.hasSF = true)
              : (issues.hasEndingSF = true)
          }
        }
        if (cube.futureOrder) {
          if (cube.futureOrder.reservedUntil) {
            issues.isReserved = true
          } else {
            const { startsAt: cubeStart, endsAt, earlyCanceledAt, autoExtendsBy, canceledAt } = cube.futureOrder
            let cubeEnd = ((autoExtendsBy && !canceledAt) ? null : endsAt)
            if (earlyCanceledAt && (!cubeEnd || earlyCanceledAt < cubeEnd)) {
              cubeEnd = earlyCanceledAt
            }
            const cubeStartsBeforeOrderEnds = Boolean(!orderCubeEnd || cubeStart <= orderCubeEnd)
            const cubeStartsAfterOrderEnds = Boolean(orderCubeEnd && cubeStart > orderCubeEnd)
            const cubeEndsBeforeOrderStarts = Boolean(cubeEnd && cubeEnd < orderStart)
            const orderEndsBeforeCubeStarts = Boolean(cubeStartsBeforeOrderEnds && !cubeEndsBeforeOrderStarts && !cubeStartsAfterOrderEnds)
            if (['Contract', 'Booking'].includes(cube.futureOrder.className)) {
              orderEndsBeforeCubeStarts || selected.className === 'FrameMount' // frame mounts dont have a start date
                ? (issues.hasOrder = true)
                : (issues.hasEndingOrder = true)
            }
            if (cube.futureOrder.className === 'SpecialFormat') {
              orderEndsBeforeCubeStarts || selected.className === 'FrameMount' // frame mounts dont have a start date
                ? (issues.hasSF = true)
                : (issues.hasEndingSF = true)
            }
          }
        }

        // Frame mounts
        if (cube.fm?.qty || cube.fm?.until) {
          issues.hasFM = true
        }
        if (cube.draftOrders?.length) {
          issues.hasDrafts = true
        }
        if (cube.openOffers?.length) {
          issues.hasOpenOffers = true
        }
        if (cube.notVerified) {
          issues.notVerified = true
        }
        if (cube.missingPhotos) {
          issues.missingPhotos = true
        }
        const flags = rootGetters.cubeFlagKeys.filter(key => cube.flags?.includes(key))
        if (flags.length) {
          issues.hasFlags = flags
          for (const key of flags) {
            issues[key] = true
          }
        }
        return Object.keys(issues).length ? { cubeId, issues } : null
      })
      .filter(Boolean)
      .reduce((acc, { cubeId, issues }) => {
        acc[cubeId] = issues
        return acc
      }, {})
  },
  getIssuesStatus: (state, getters, rootState, rootGetters) => (issues) => {
    if (!issues) { return null }
    const { className } = state.selected
    const { hasOrder, isReserved, hasFM, hasSF, hasVOD } = issues

    // Errors VODHIDE
    if (hasVOD || isReserved) { return 'error' }
    if (className !== 'Offer' && (hasOrder || hasFM)) { return 'error' }
    if (hasSF && className === 'SpecialFormat') { return 'error' }

    // Warnings
    if (className === 'Offer' && (hasOrder || hasFM)) { return 'warning' }
    const { hasDrafts, hasEndingOrder, hasEndingSF, hasOpenOffers, missingPhotos, notVerified } = issues
    if (hasDrafts || hasEndingOrder || hasEndingSF || hasOpenOffers) { return 'warning' }
    const errorFlagKeys = rootGetters.errorFlagKeys
    if (errorFlagKeys.some(key => issues[key])) { return 'warning' }
    const warningFlagKeys = rootGetters.warningFlagKeys
    if (warningFlagKeys.some(key => issues[key])) { return 'warning' }

    // Infos
    if (missingPhotos || notVerified) { return 'info' }
    return null
  },
  cubeStatuses(state, getters, rootState, rootGetters) {
    return Object.keys(getters.issues).reduce((acc, cubeId) => {
      const status = getters.getIssuesStatus(getters.issues[cubeId])
      if (status) { acc[cubeId] = status }
      return acc
    }, {})
  },
  totalIssues(state, getters) {
    if (!getters.issues || !Object.keys(getters.issues).length) { return }
    const issueCubeIds = state.checkOnly
      ? Object.keys(getters.issues).filter(cubeId => state.checkOnly.includes(cubeId))
      : Object.keys(getters.issues)
    return issueCubeIds.reduce((acc, cubeId) => {
      const cubeIssues = getters.issues[cubeId]
      Object.keys(cubeIssues).forEach((key) => {
        if (!acc[key]) { acc[key] = 0 }
        acc[key] += 1
      })
      return acc
    }, {})
  },
  issueStatus: (state, getters) => getters.getIssuesStatus(getters.totalIssues),
  issueDict: (state, getters) => {
    if (!state.selected) { return {} }
    return {
      hasOrder: 'Bereits vermarktet',
      isReserved: 'Reserviert',
      hasFM: 'Mit Moskitorahmen',
      hasSF: 'Sonderformat vorhanden',
      hasDrafts: 'Entwurf von Aufträgen',
      hasOpenOffers: 'Hat offene Angebote',
      earlyCanceled: state.selected.className === 'FrameMount'
        ? 'Rahmen demontiert'
        : 'Frühzeitig storniert',
      hasFlags: 'Hat Ausschlusskriterien',
      notVerified: 'Nicht verifiziert',
      missingPhotos: 'Fehlende Fotos'
    }
  }
}

// mutations
export const mutations = {
  TOGGLE_DRAWER(state, drawer) {
    if (!state.selected) {
      state.drawer = false
      return
    }
    if (drawer === undefined) {
      state.drawer = !state.drawer
      return
    }
    if ([true, false].includes(drawer)) {
      state.drawer = drawer
      return
    }
    if (drawer === null && vuetify.framework.breakpoint.xlOnly) {
      state.drawer = true
    }
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_NOT_FOUND(state, notFoundClassName) {
    state.notFound = notFoundClassName
  },
  SET_SELECTED(state, selected) {
    state.notFound = null
    state.selected = selected
  },
  ADD_CUBES(state, cubeIds) {
    state.cubeIds = [...new Set([...state.cubeIds, ...cubeIds])].sort()
  },
  ADD_CUBE(state, cubeId) {
    state.cubeIds = [...new Set([...state.cubeIds, cubeId])].sort()
  },
  REMOVE_CUBE(state, objectId) {
    state.cubeIds = state.cubeIds.filter(id => id !== objectId)
  },
  CLEAR_CUBES(state) {
    state.cubeIds = []
  },
  UPDATE_SELECTION_RATING(state, { cubeId, selectionRating }) {
    if (!state.selected.selectionRatings) {
      set(state.selected, 'selectionRatings', {})
    }
    selectionRating
      ? set(state.selected.selectionRatings, cubeId, selectionRating)
      : Vue.delete(state.selected.selectionRatings, cubeId)
  },
  TOGGLE_STAR(state, { cubeId }) {
    if (!state.selected.stars) {
      set(state.selected, 'stars', {})
    }
    state.selected.stars[cubeId]
      ? Vue.delete(state.selected.stars, cubeId)
      : set(state.selected.stars, cubeId, true)
  },
  SET_LOCATIONS(state, locations) {
    state.locations = locations
  },
  ADD_LOCATIONS(state, locations) {
    for (const cubeId of Object.keys(locations)) {
      set(state.locations, cubeId, (state.locations[cubeId] || 0) + locations[cubeId])
    }
  },
  SUBTRACT_LOCATION(state, location) {
    state.locations[location]--
  },
  REMOVE_LOCATION(state, cubeId) {
    Vue.delete(state.locations, cubeId)
  },
  CLEAR_LOCATIONS(state) {
    state.locations = {}
  },
  SET_ISSUES(state, issues) {
    state.issues = issues
  },
  ADD_ISSUES(state, issues) {
    for (const cubeId of Object.keys(issues)) {
      set(state.issues, cubeId, issues[cubeId])
    }
  },
  REMOVE_ISSUE(state, cubeId) {
    Vue.delete(state.issues, cubeId)
  },
  CLEAR_ISSUES(state) {
    state.issues = {}
  },
  UPDATE_CHECK_ONLY(state, cubeIds) {
    if (cubeIds === false) {
      state.checkOnly = false
    }
    if (Array.isArray(cubeIds)) {
      state.checkOnly = cubeIds
    }
  }
}

// actions
export const actions = {
  toggleDrawer({ commit }, drawer) {
    commit('TOGGLE_DRAWER', drawer)
  },
  resetCubes({ state, commit }) {
    commit('ADD_CUBES', state.selected.cubeIds)
    // remove cubes not in object.cubeIds
    for (const cubeId of state.cubeIds) {
      if (!state.selected.cubeIds.includes(cubeId)) {
        commit('REMOVE_CUBE', cubeId)
      }
    }
  },
  async select({ state, commit, getters, dispatch }, { className, objectId, noFetch, resetCubes }) {
    if (noFetch) {
      commit('SET_SELECTED', noFetch)
      getters.hasCubes && dispatch('resetCubes')
      commit('TOGGLE_DRAWER', null)
      return
    }
    const selectingSame = className === state.selected?.className && objectId === state.selected?.objectId
    if (objectId !== state.selected?.objectId || className !== state.selected?.className) {
      dispatch('discard')
    }
    let object
    try {
      commit('SET_LOADING', className)
      object = await (new Parse.Query(className))
        .include('all')
        .get(objectId)
        .then(normalize)
      commit('SET_SELECTED', object)
      if (getters.hasCubes && (!selectingSame || resetCubes)) {
        dispatch('resetCubes')
      }
      !selectingSame && commit('TOGGLE_DRAWER', null)
    } catch (error) {
      if (error.code !== 101) {
        throw error
      }
      commit('SET_NOT_FOUND', className)
    }
    await dispatch('fetchLocations')
    commit('SET_LOADING', false)
    return object
  },
  reselect({ state, rootState, dispatch }) {
    if (!state.selected) { return }
    const { className, objectId } = state.selected
    const noFetch = className === '_User' && rootState.users[objectId]
    return dispatch('select', { className, objectId, noFetch, resetCubes: true })
  },
  async add({ state, commit, dispatch }, cubeIds) {
    if (typeof cubeIds === 'string') {
      cubeIds = [cubeIds]
    }
    if (!state.selected) {
      throw new Error('Please select a list first!')
    }
    // make sure issues are updated with the new cubeIds
    commit('ADD_CUBES', cubeIds)
    await dispatch('fetchLocations', cubeIds)
    await dispatch('fetchIssues', cubeIds)
  },
  async remove({ commit, state }, objectId) {
    if (!state.selected) { return }
    const pk = await new Parse.Query('Cube').select('pk').get(objectId)
      .then(cube => cube.get('pk'))
    commit('SUBTRACT_LOCATION', pk)
    commit('REMOVE_CUBE', objectId)
  },
  removeMany({ commit, state, dispatch }, objectIds) {
    if (!state.selected) { return }
    if (state.selected.className === 'FrameMount' && objectIds.some(id => state.selected.fmCounts?.[id])) {
      throw new Error('Bitte entfernen Sie zuerst die Rahmen, bevor Sie die CityCubes entfernen.')
    }
    const confirmMessage = objectIds.length > 1
      ? `Sind Sie sicher, dass Sie diese ${objectIds.length} CityCubes entfernen möchten?`
      : 'Sind Sie sicher, dass Sie diesen CityCube entfernen möchten?'
    if (!confirm(confirmMessage)) { return }
    objectIds.map(objectId => commit('REMOVE_CUBE', objectId))
    dispatch('snackbar/success', pluralize(objectIds.length, 'CityCube', 'CityCubes') + ' entfernt.', { root: true })
  },
  discard({ state, commit }) {
    commit('TOGGLE_DRAWER', false)
    commit('SET_SELECTED', null)
    commit('CLEAR_CUBES')
    commit('CLEAR_LOCATIONS')
    commit('CLEAR_ISSUES')
    commit('SET_LOADING', false)
  },
  async submit({ commit, state, getters, dispatch }) {
    commit('SET_LOADING', state.selected.className)
    try {
      const { cubeIds } = await Parse.Cloud.run(
        kebabCase(state.selected.className) + '-update-cubes',
        { id: state.selected.objectId, cubeIds: state.cubeIds }
      ).then(normalize)
      dispatch('snackbar/success', 'CityCubes gespeichert.', { root: true })
      dispatch('select', { noFetch: { ...state.selected, cubeIds } })
    } catch (error) {
      dispatch('snackbar/error', error.message, { root: true })
    }
    commit('SET_LOADING', false)
  },
  async rateSelection({ commit, state, dispatch }, { cubeId, selectionRating }) {
    try {
      await Parse.Cloud.run(
        kebabCase(state.selected.className) + '-rate-selection',
        { id: state.selected.objectId, cubeId, selectionRating }
      )
      commit('UPDATE_SELECTION_RATING', { cubeId, selectionRating })
    } catch (error) {
      dispatch('snackbar/error', error.message, { root: true })
    }
    commit('SET_LOADING', false)
  },
  async toggleStar({ commit, state, dispatch }, { cubeId }) {
    try {
      await Parse.Cloud.run(
        kebabCase(state.selected.className) + '-toggle-star',
        { id: state.selected.objectId, cubeId }
      )
      commit('TOGGLE_STAR', { cubeId })
    } catch (error) {
      dispatch('snackbar/error', error.message, { root: true })
    }
    commit('SET_LOADING', false)
  },
  // locations
  async fetchLocations({ state, commit }, addCubeIds) {
    const selected = state.selected
    if (!selected) { return }
    const locations = await Parse.Cloud.run('cube-locations', {
      className: selected.className,
      objectId: selected.objectId,
      cubeIds: addCubeIds || state.cubeIds
    }).then(normalize)
    if (addCubeIds) {
      commit('ADD_LOCATIONS', locations)
      for (const cubeId of addCubeIds) {
        if (!locations[cubeId]) {
          commit('REMOVE_LOCATION', cubeId)
        }
      }
      return
    }
    commit('SET_LOCATIONS', locations)
  },
  // issues (addCubeIds only fetches issues for particular cubes)
  async fetchIssues({ state, commit }, addCubeIds) {
    const selected = state.selected
    if (!selected) { return }
    if (selected.className !== 'FrameMount' && selected.status >= 3) { return }
    const issues = await Parse.Cloud.run('order-finalize-issues', {
      className: selected.className,
      objectId: selected.objectId,
      cubeIds: addCubeIds || state.cubeIds
    }).then(normalize)
    if (addCubeIds) {
      commit('ADD_ISSUES', issues)
      for (const cubeId of addCubeIds) {
        if (!issues[cubeId]) {
          commit('REMOVE_ISSUE', cubeId)
        }
      }
      return
    }
    commit('SET_ISSUES', issues)
  },
  clearIssues({ commit }) {
    commit('CLEAR_ISSUES')
  },
  updateCheckOnly({ commit }, cubeIds) {
    commit('UPDATE_CHECK_ONLY', cubeIds)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
