// import moment from 'moment'
import posthog from '@/plugins/posthog';
export default {
  name: 'App',
  watch: {
    '$vuetify.theme.dark': function $vuetifyThemeDark(dark) {
      window.localStorage.setItem('dark', "".concat(dark));
    },
    '$route.meta.title': function $routeMetaTitle(title) {
      !this.$store.state.title && this.$store.dispatch('setTitle', title);
    },
    '$store.state.title': {
      immediate: true,
      handler: function handler(title) {
        document.title = title ? title + ' | ' + this.$siteTitle : this.$siteTitle;
      }
    },
    '$store.state.cookieConsent.current.oneSignal': {
      immediate: true,
      handler: function handler(consent) {
        // https://documentation.onesignal.com/docs/web-push-sdk
        if (!consent) {
          return;
        }
        var user = this.$store.state.user;
        if (!user) {
          return;
        }
        this.$OneSignal.init({
          appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
          safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID,
          allowLocalhostAsSecureOrigin: true,
          notificationClickHandlerMatch: 'origin',
          notificationClickHandlerAction: 'navigate',
          welcomeNotification: {
            message: 'Sie haben sich für Push-Benachrichtigungen angemeldet. So werden die Benachrichtigungen auf Ihrem Gerät angezeigt.'
          },
          autoResubscribe: true
        });
        this.$OneSignal.setExternalUserId(user.objectId);
      }
    },
    '$store.state.user': {
      immediate: true,
      handler: function handler(user) {
        if (user && this.$mode === 'production') {
          posthog.identify(user.objectId, {
            email: user.username,
            name: user.firstName + ' ' + user.lastName
          });
          posthog.opt_in_capturing();
          return;
        }
        posthog.reset();
      }
    }
  },
  methods: {
    unload: function unload() {
      var _document$getElements, _document$getElements2, _document$getElements3;
      (_document$getElements = document.getElementsByTagName('html')[0]) === null || _document$getElements === void 0 ? void 0 : (_document$getElements2 = _document$getElements.classList) === null || _document$getElements2 === void 0 ? void 0 : _document$getElements2.remove('loading');
      window.localStorage.getItem('app') && ((_document$getElements3 = document.getElementsByTagName('html')[0]) === null || _document$getElements3 === void 0 ? void 0 : _document$getElements3.setAttribute('data-which-app', window.localStorage.getItem('app')));
    }
  }
};